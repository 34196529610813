import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Text, { Variant } from '@components/ui/Text/Text';
import usePrice from '@framework/product/use-price';

import { getPercentSavings } from '../helpers';

interface PricingProps {
  price?: number;
  originalPrice?: number;
  currencyCode?: string;
  textVariant?: Variant;
  className?: string;
  textClassName?: string;
  subTextClassName?: string;
  discountTextClassName?: string;
  hideRegPriceLabel?: boolean;
}

const Pricing: FC<PricingProps> = ({
  price: rawPrice,
  originalPrice: rawOGPrice,
  currencyCode,
  textVariant = 'heading-3',
  className,
  textClassName,
  subTextClassName,
  discountTextClassName,
  hideRegPriceLabel = false,
}: PricingProps) => {
  const { t } = useTranslation(['common', 'product']);

  const { price } = usePrice({
    amount: Number(rawPrice),
    currencyCode,
  });

  const { price: originalPrice } = usePrice({
    amount: Number(rawOGPrice),
    currencyCode,
  });

  const pricesAreEqual = price === originalPrice;
  const percentDiscount = getPercentSavings(rawPrice, rawOGPrice);

  return (
    <div className={cn('flex flex-wrap items-baseline', className)}>
      {price && (
        <>
          <Text variant={textVariant} className={textClassName} asElement="span" data-cy="pricing" aria-hidden>
            {price}
          </Text>
          <p className="sr-only">{t('product:pricing.current', { price })}</p>
        </>
      )}
      {originalPrice && !pricesAreEqual && (
        <Text
          variant="text-4"
          className={cn('ml-1 mr-2', subTextClassName)}
          asElement="span"
          weight="normal"
          color="var(--black-500)"
          aria-hidden
          data-cy="original-pricing"
        >
          {hideRegPriceLabel ? originalPrice : t('product:pricing.regPrice', { price: originalPrice })}
        </Text>
      )}
      {percentDiscount && (
        <Text
          variant="text-4"
          className={cn(subTextClassName, discountTextClassName)}
          asElement="span"
          weight="medium"
          color="var(--red-200)"
          aria-hidden
          data-cy="percent-discount"
        >
          {t('product:pricing.percentDiscount', { discount: percentDiscount })}
        </Text>
      )}
    </div>
  );
};

export default Pricing;
